import * as React from "react"
import { Link } from "gatsby"

import "./footer.scss"

export default function Footer() {
  return (
    <footer className="footerStyle">
      <hr />
      <nav className="links" aria-label="footer">
        <ul className="footerNavList">
          <li className="footerNavListItem">
            <Link to="/legal/en/" className="legal-link_style">
              Legal
            </Link>{" "}
          </li>
          <li className="footerNavListItem">
            <Link to="/privacy/en/" className="legal-link_style">
              Privacy
            </Link>{" "}
          </li>
        </ul>
      </nav>
      <div>
        <p className="copyright">
          <i>We don't use cookies</i>
        </p>
        <p className="copyright">
          Want a page like that ?{" "}
          <a
            href="https://www.urule.dev"
            rel="noopener noreferrer"
            target="_blank"
            className="legal-link_style"
          >
            Contact us
          </a>
        </p>
        <p className="copyright">
          Copyright &copy; OZENKAWA & SACHA {new Date().getFullYear()}
        </p>
      </div>
    </footer>
  )
}
