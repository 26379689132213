import * as React from "react"
import Footer from "../Footer/Footer"

import "./layout.scss"

export default function Layout({ children }) {
  return (
    <div>
      {children}
      <Footer />
    </div>
  )
}
